import React, { Fragment, useContext, useEffect, useReducer, useState } from 'react';
import Screen from 'utils/helpers/ScreenSize';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { StoreContext } from 'pages/_app';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import { setAccessToken } from 'utils/helpers/localStorage';
import { useRouter } from 'next/router';
import SingleAddressConfirmation from 'components/SingleAddressConfirmation';
import DuplicateAddressConfirmation from 'components/DuplicateAddressConfirmation';
import { compareAddresses } from 'utils/helpers/addresses';

function AuthModal({ visible, setModal }) {
  const screenType = Screen.ScreenType();
  const store = useContext(StoreContext);
  const cartStore = toJS(store?.cartStore);
  const userStore = toJS(store?.userStore);

  let { unCheckedOutOrder } = cartStore;
  let { currentUser, locationAddresses, completeAddress, address, locationId } = userStore;
  let router = useRouter();

  const initialState = {
    email: '',
    password: '',
    showPassword: false,
    error: '',

    locationId: '',
    isAdmin: false,

    duplicateAddresses: [],
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [duplicateAddress, setDuplicateAddresses] = useState(false);
  const [showAddressModals, setShowAddressModals] = useState(false);

  let { showPassword, email, password, error } = provider;

  useEffect(() => {
    dispatch({ field: 'email', value: '' });
    dispatch({ field: 'password', value: '' });
    dispatch({ field: 'error', value: '' });
    dispatch({ field: 'showPassword', value: false });
  }, [visible]);

  const forgotPassword = e => {
    e.preventDefault();

    store.userStore.setLoginModal(false);

    setTimeout(() => {
      store.userStore.setForgotModal(true);
    }, 300);
  };

  const setDuplicateAddress = locationAddresses => {
    const { duplicateAddressesFound, duplicateAddresses } = compareAddresses(
      locationAddresses,
      completeAddress
    );

    setDuplicateAddresses(duplicateAddressesFound);
    dispatch({ field: 'duplicateAddresses', value: duplicateAddresses });
  };

  const getAllLocationAddresses = async adminLocationIds => {
    if (adminLocationIds?.length > 0) {
      let allAddresses = [];

      try {
        await Promise.all(
          adminLocationIds?.map(async locationId => {
            try {
              const response = await store.userStore.getLocationAddresses(locationId);
              const addresses = response?.data;

              if (addresses) {
                const addressesWithLocationId = addresses.map(address => ({
                  ...address,
                  locationId,
                }));
                allAddresses.push(...addressesWithLocationId);
              }
            } catch (error) {
              console.log('err', error);
              store.userStore?.setLoader(false);
            }
          })
        );

        store.userStore.setLocationAddresses(allAddresses);
        setDuplicateAddress(allAddresses);

        store.userStore?.setLoader(false);
      } catch (error) {
        console.log('err', error);
        store.userStore?.setLoader(false);
      }
    }
  };

  const onLoginPress = e => {
    e.preventDefault();

    store.userStore?.setLoader(true);
    store.userStore
      .loginUser({ email, password })
      .then(response => {
        store.userStore?.setLoader(false);

        if (response?.data?.errors) dispatch({ field: 'error', value: response?.data?.errors });

        if (response?.data?.attributes) {
          setAccessToken(response?.data?.attributes?.access_token);
          store.userStore?.setIsUserLogin(true);

          const activeEmployees = response?.data?.attributes?.employees.filter(
            emp => !emp?.attributes.soft_deleted
          );

          // admin user with enable_invoice_payments
          const adminUserWithPayments = activeEmployees?.find(
            emp =>
              (emp?.attributes.role == 'admin' || emp?.attributes.role == 'guest') &&
              emp?.attributes.invoice_payments == 'enabled'
          );

          // If no admin user with enable-invoices then find any admin user
          const adminUser =
            adminUserWithPayments ||
            activeEmployees?.find(
              emp => emp?.attributes.role == 'admin' || emp?.attributes.role == 'guest'
            );

          const uncateringUser = activeEmployees?.find(emp => emp?.attributes.uncatering);

          const adminEmployees = activeEmployees?.filter(
            emp => emp?.attributes.role == 'admin' || emp?.attributes.role == 'guest'
          );
          const adminLocationIds = adminEmployees?.map(emp => emp?.attributes?.organization_id);

          store.userStore.setAdminLocationIds(adminLocationIds);

          const fullTimeUserWithPayments = activeEmployees?.find(
            emp =>
              emp?.attributes.role == 'full_time' && emp?.attributes.invoice_payments == 'enabled'
          );

          // Full-time user without enable_invoice_payments
          const fullTimeUser =
            fullTimeUserWithPayments ||
            activeEmployees?.find(emp => emp?.attributes.role == 'full_time');

          if (adminUser) {
            dispatch({ field: 'locationId', value: adminUser?.attributes?.organization_id });
            dispatch({ field: 'isAdmin', value: true });

            store.userStore.setLocationID(adminUser?.attributes?.organization_id);
            store.userStore.setAdminUser(true);
            store.userStore.setEmployeeID(adminUser?.id);

            store.userStore.setInvoiceDetails({
              invoice_payments: adminUser?.attributes?.invoice_payments,
              account_billing_address: adminUser?.attributes?.account_billing_address,
              account_billing_emails: adminUser?.attributes?.account_billing_emails,
              account_billing_name: adminUser?.attributes?.account_billing_name,
            });
          } else {
            dispatch({ field: 'locationId', value: fullTimeUser?.attributes?.organization_id });

            store.userStore.setLocationID(fullTimeUser?.attributes?.organization_id);
            store.userStore.setAdminUser(false);
            store.userStore.setEmployeeID(fullTimeUser?.id);

            store.userStore.setInvoiceDetails({
              invoice_payments: fullTimeUser?.attributes?.invoice_payments,
              account_billing_address: fullTimeUser?.attributes?.account_billing_address,
              account_billing_emails: fullTimeUser?.attributes?.account_billing_emails,
              account_billing_name: fullTimeUser?.attributes?.account_billing_name,
            });
          }

          getAllLocationAddresses(adminLocationIds);

          if (uncateringUser) {
            store.userStore.setUncateringUser(true);
            store.userStore.setUncateringEmployeeId(uncateringUser?.id);
          }

          setModal(false);

          const existingOrder =
            unCheckedOutOrder?.id || response?.data?.attributes?.uncheckedout_order_id;

          if (existingOrder) {
            store?.cartStore?.getCartItems(existingOrder);
          }

          if (router?.pathname == '/new_account') router.push('/');

          if (router?.query?.address || router?.pathname == '/checkout/[slug]')
            setShowConfirmationModal(true);
        }
      })
      .catch(err => {
        console.log('err', err);
        store.userStore?.setLoader(false);
      });
  };

  const submitButtons = (
    <div className="flex flex-col">
      <button type="submit" className="btn-purple mt-5 h-12">
        Sign In
      </button>
    </div>
  );

  return (
    <>
      <Transition appear show={visible} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => {
            screenType?.isTabletOrDesktop && setModal(false);
          }}>
          <form onSubmit={onLoginPress}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed top-0 right-0 left-0 sm:inset-0 overflow-y-auto">
              <div className="flex min-h-screen sm:min-h-full sm:items-center sm:justify-center sm:p-4 md:p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95">
                  <Dialog.Panel className="flex flex-col flex-1 md:flex-initial w-4/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white py-10 px-8 text-left align-middle shadow-xl transition-all">
                    <div className="flex justify-end mb-3">
                      <button type="button" onClick={() => setModal(false)} className="btn-style">
                        <FontAwesomeIcon
                          style={{ width: 16, height: 26 }}
                          icon={faXmark}
                          size="2x"
                          color="#BBC0C5"
                        />
                      </button>
                    </div>

                    <div className="flex w-full justify-center items-center">
                      <Dialog.Title
                        as="h3"
                        style={{
                          fontSize: screenType?.isTabletOrMobile ? 23 : 32,
                        }}
                        className="font-inter-semibold leading-6 text-black-light">
                        <span className="text-[24px]">Log in to your account</span>
                      </Dialog.Title>
                    </div>

                    <div className="flex flex-col justify-center items-center flex-wrap mt-10 md:w-full">
                      <div className="flex flex-col w-full">
                        <input
                          required
                          type="email"
                          className="input-gray-bg-border text-md"
                          placeholder="Email"
                          value={provider.email}
                          onChange={e => {
                            if (error) dispatch({ field: 'error', value: '' });
                            dispatch({ field: 'email', value: e.target.value });
                          }}
                        />

                        <div className="flex justify-between text-md font-inter-regular rounded-xl bg-background w-full overflow-hidden focus:outline-none h-12 border border-border-gray mt-3">
                          <input
                            required
                            className="flex w-full bg-background focus:outline-none rounded-l-md px-3"
                            placeholder="Password"
                            type={showPassword ? 'text' : `password`}
                            value={provider.password}
                            onChange={e => {
                              if (error) dispatch({ field: 'error', value: '' });

                              dispatch({
                                field: 'password',
                                value: e.target.value,
                              });
                            }}
                          />

                          <button
                            type="button"
                            onClick={() =>
                              dispatch({
                                field: 'showPassword',
                                value: !showPassword,
                              })
                            }
                            className="btn-style">
                            <span className="text-hh-accent-light text-md px-3">
                              {showPassword ? 'Hide' : 'Show'}
                            </span>
                          </button>
                        </div>

                        <button
                          type="button"
                          onClick={e => forgotPassword(e)}
                          className="mt-2 btn-style sm:mt-1 flex justify-center sm:justify-start sm:items-start">
                          <span className="text-sm font-inter-light underline text-indigo-500">
                            Forgot password?
                          </span>
                        </button>

                        {error && (
                          <span className="mt-5 text-red-600 text-md font-inter-medium">
                            {error}
                          </span>
                        )}

                        <div className="hidden sm:flex flex-col">{submitButtons}</div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>

            <Transition.Child
              as={'div'}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="sm:hidden">
                <div className="fixed bg-white w-full p-5 bottom-0 z-[90] justify-center items-center mt-10 border-t-2 border-gray-200">
                  {submitButtons}
                </div>
              </div>
            </Transition.Child>
          </form>
        </Dialog>
      </Transition>

      {showConfirmationModal && provider.isAdmin && (
        <>
          {duplicateAddress ? (
            <DuplicateAddressConfirmation
              visible={true}
              setModal={setShowConfirmationModal}
              addresses={provider?.duplicateAddresses}
            />
          ) : (
            <SingleAddressConfirmation
              visible={true}
              setModal={setShowConfirmationModal}
              locationID={provider?.locationId || locationId}
            />
          )}
        </>
      )}
    </>
  );
}

export default observer(AuthModal);
